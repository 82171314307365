import React, { InputHTMLAttributes, useCallback } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import * as Checkbox from '@radix-ui/react-checkbox';
import { MdCheck } from 'react-icons/md';
import classNames from 'classnames';

export type FormCheckboxInputInputProps = Omit<InputHTMLAttributes<HTMLElement>, 'name' | 'onChange' | 'onBlur'> &
  Omit<GenericFormInputProps, 'label'> & {
    onChange?: (value: boolean) => void;
    label?: string | React.ReactNode;
    labelAbove?: boolean;
    description?: string | React.ReactNode;
  };

export const FormCheckboxInput = ({
  containerClassName,
  containerTestId,
  name,
  label,
  labelAbove = false,
  description,
  tooltip,
  required,
  testId,
  onChange,
  renderAfter,
  renderBefore,
  className = '',
  labelClassName = '',
  value: propValue = 1,
}: FormCheckboxInputInputProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<boolean | null | undefined | string[]>(
    name,
  );

  const onClick = useCallback(() => {
    if (Array.isArray(value) && typeof propValue === 'string') {
      const removing = value.includes(propValue.toString());
      if (removing) {
        setValue(value.filter((v) => v !== propValue));
      } else {
        setValue([...value, propValue]);
      }
    } else {
      setValue(!value);
    }

    onChange && onChange(!value);

    setTimeout(() => {
      setTouched(true);
    }, 10);
  }, [onChange, propValue, setTouched, setValue, value]);

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      tooltip={tooltip}
      required={required}
      testId={testId}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
      label={labelAbove && label ? String(label) : undefined}
    >
      <fieldset
        id={name}
        className={classNames(
          'checkbox flex',
          {
            'items-center': !labelAbove,
            'h-[42px] flex-col justify-center items-start': labelAbove,
          },
          className,
        )}
      >
        <Checkbox.Root
          id={name}
          name={name}
          aria-label={label?.toString()}
          className="appearance-none rounded-[4px] outline-none min-w-[18px]"
          value={propValue}
          onClick={onClick}
          checked={Array.isArray(value) ? value.includes(propValue.toString()) : !!value}
        >
          <Checkbox.Indicator className="text-blue-main">
            <MdCheck className="pt-[2px]" />
          </Checkbox.Indicator>
        </Checkbox.Root>
        {!labelAbove && label && (
          <label
            className={classNames('pl-[10px] text-[15px] leading-none', labelClassName)}
            htmlFor={name}
            onClick={onClick}
          >
            {label}
          </label>
        )}
      </fieldset>
      {description ? <p className="text-14 leading-tight text-gray-200 mb-0 mt-2">{description}</p> : null}
    </FormInputContainer>
  );
};
