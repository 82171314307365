import { ForwardedRef, KeyboardEvent, ReactElement, useState } from 'react';
import { MdTune } from 'react-icons/md';
import { StyledButton } from '../button/styled-button';
import { StyledInput, StyledInputProps } from '../form/styled-input';
import { StyledSelect, StyledSelectProps } from '../form/styled-select';
import { Props, SearchSortFilterInput } from './types';

export const SearchSortFilter = ({
  button,
  displayOnInitialisation = true,
  elements,
  header,
  submitOnEnter = false,
}: Props): ReactElement => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(displayOnInitialisation);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && submitOnEnter) {
      button?.onClick();
    }
  };

  return (
    <div className="search-sort-filter" onKeyDown={submitOnEnter ? handleKeyDown : undefined}>
      <div className="search-sort-filter__header">
        {header}
        <MdTune
          className="!self-end"
          size={21.34}
          onClick={(): void => setIsDisplayed(!isDisplayed)}
          onKeyUp={(e: KeyboardEvent<SVGElement>): false | void => e.key === 'Enter' && setIsDisplayed(!isDisplayed)}
          role="button"
          tabIndex={0}
        />
      </div>
      {isDisplayed && (
        <div className="search-sort-filter__body">
          {elements.map(
            ({ element, ref }: SearchSortFilterInput, index: number): ReactElement => (
              <div key={index} className="search-sort-filter__input-wrapper self-start">
                {element?.type === 'select' ? (
                  <StyledSelect
                    {...(element as StyledSelectProps)}
                    className="!bg-white"
                    ref={ref as ForwardedRef<HTMLSelectElement>}
                  />
                ) : (
                  <StyledInput
                    {...(element as StyledInputProps)}
                    className="!bg-white"
                    ref={ref as ForwardedRef<HTMLInputElement>}
                  />
                )}
              </div>
            ),
          )}
          {button && (
            <div className="search-sort-filter__input-wrapper ml-auto justify-center">
              <StyledButton
                buttonType="secondary"
                className="!w-auto"
                data-testid="search-sort-filter-button"
                disabled={button.disabled}
                onClick={button.onClick}
              >
                {button.text}
              </StyledButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchSortFilter;
